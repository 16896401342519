import axios from 'axios';
import global from '../../components/Global';

let base = global.serverBase;

export const getEventsDefineList= params => { return axios.post(base + '/eventsDefineManage/getEventsDefineList', params)};

export const queryEventsDefineByParam= params => { return axios.post(base + '/eventsDefineManage/queryEventsDefineByParam', params)};

export const addEventsDefine = params => {return axios.post(base + '/eventsDefineManage/addEventsDefine', params)};

export const modifyEventsDefine = params => {return axios.post(base + '/eventsDefineManage/modifyEventsDefine', params)};

export const delEventsDefine = params => {return axios.post(base + '/eventsDefineManage/delEventsDefine', params)};

export const getDeviceTypeList = params => {return axios.post(base + '/eventsDefineManage/getDeviceTypeList', params)};
