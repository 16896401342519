<template>
  <div>
    <NavBreadcrumb></NavBreadcrumb>
    <div class="panel-style eventsDefineManage">
      <el-form :inline="true" :model="searchForm">
        <el-row>
          <el-form-item label="事件分类">
            <el-select v-model="searchForm.classify" clearable placeholder="请选择事件分类" style="width:150px">
              <el-option label="环境" value="环境"></el-option>
              <el-option label="柴发控制器" value="柴发控制器"></el-option>
              <el-option label="锂电池BMS" value="锂电池BMS"></el-option>
              <el-option label="电源变换器" value="电源变换器"></el-option>
              <el-option label="监控自定义" value="监控自定义"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件码">
            <el-input v-model="searchForm.code" clearable placeholder="请输入事件码" style="width:150px"/>
          </el-form-item>
          <el-form-item label="事件名称">
            <el-input v-model="searchForm.name" clearable placeholder="请输入事件名称" style="width:150px"/>
          </el-form-item>
          <el-form-item label="事件等级">
            <el-select v-model="searchForm.level" clearable placeholder="请选择事件等级" style="width:150px">
              <el-option label="1级" value="1"></el-option>
              <el-option label="2级" value="2"></el-option>
              <el-option label="3级" value="3" style="color:red"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="包含子事件">
            <el-select v-model="searchForm.isTop" clearable placeholder="请选择是否包含子事件" style="width:150px">
              <el-option label="否" value="0"></el-option>
              <el-option label="是" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getTableData">查询</el-button>
            <el-button type="primary" @click="openAddEventsDefinePage">添加</el-button>
          </el-form-item>
        </el-row>
        <el-row style="display: none">
          <el-form-item label="发生钉钉通知">
            <el-select v-model="searchForm.type1DDNotify" clearable placeholder="事件发生后钉钉通知" style="width:180px">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="解除钉钉通知">
            <el-select v-model="searchForm.type0DDNotify" clearable placeholder="事件解除后钉钉通知" style="width:180px">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <div style="height:560px;">
        <el-scrollbar wrap-style="overflow-x:hidden;" style="height:100%;">
          <el-table :data="tableData" border size="small" stripe style="width: 100%" height="560"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column prop="code" label="事件码" width="60px"/>
            <el-table-column prop="classify" label="事件分类"/>
            <el-table-column prop="name" label="事件名称"/>
            <el-table-column prop="type1Name" label="发生名称" align="center"/>
            <el-table-column prop="type0Name" label="解除名称" align="center"/>
            <el-table-column prop="type1DDNotify" label="发生钉钉通知" align="center">
              <template slot-scope="scope">
                                <span v-if="scope.row.type1DDNotify==='1'" style="color: red;cursor: pointer"
                                      title="已开启事件发生钉钉通知">
                                    <i style="font-size: 20px;" class="el-icon-circle-check"></i>
                                </span>
                <span v-else-if="scope.row.type1DDNotify==='0'" style="cursor: pointer" title="已关闭事件发生钉钉通知">
                                    <i style="font-size: 20px;" class="el-icon-circle-close"></i>
                                </span>
              </template>
            </el-table-column>
            <el-table-column prop="type0DDNotify" label="解除钉钉通知" align="center">
              <template slot-scope="scope">
                                <span v-if="scope.row.type0DDNotify==='1'" style="color: red;cursor: pointer"
                                      title="已开启事件解除钉钉通知">
                                    <i style="font-size: 20px;" class="el-icon-circle-check"></i>
                                </span>
                <span v-else-if="scope.row.type0DDNotify==='0'" style="cursor: pointer" title="已关闭事件解除钉钉通知">
                                    <i style="font-size: 20px;" class="el-icon-circle-close"></i>
                                </span>
              </template>
            </el-table-column>
            <el-table-column prop="level" label="事件等级" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.level==='1'">1级</span>
                <span v-else-if="scope.row.level==='2'">2级</span>
                <span v-else-if="scope.row.level==='3'" style="color: red">3级</span>
              </template>
            </el-table-column>
            <el-table-column prop="level" label="包含子事件" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.isTop==='0'">否</span>
                <span v-else-if="scope.row.isTop==='1'">是</span>
              </template>
            </el-table-column>
            <el-table-column prop="majorClassName" label="所属组件" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.majorClassName===null">--</span>
                <span v-else>{{ scope.row.majorClassName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="事件说明" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.remark==='' || scope.row.remark===null">--</span>
                <el-tooltip v-else effect="dark" placement="top-start">
                  <div style="margin-top: 5px" slot="content">
                    <span>{{ scope.row.remark }}</span>
                  </div>
                  <i class="el-icon-chat-dot-square" style="font-size: 15px;cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="140px" :formatter="dateFormat" align="center"/>
            <el-table-column label="操作" width="180px" align="center">
              <template slot-scope="scope">
                <el-button size="mini" @click="openEditEventsDefinePage(scope.$index, scope.row)">修改</el-button>
                <el-button size="mini" @click="handleDelete(scope.$index, scope.row)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div style="margin-top: 15px;text-align: right;">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="curr"
            :page-sizes="[10, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal">
        </el-pagination>
      </div>
      <el-dialog :title=dialogTitle :visible.sync="addOrEditFormVisible" custom-class="eventsDefine-dialog" center
                 :close-on-click-modal="false">
        <el-form :model="addOrEditForm" :rules="formRules" ref="addOrEditForm" label-width="160px" :inline="true"
                 size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="事件分类" prop="classify">
                <el-select v-bind:disabled="codeNeedVal" v-model="addOrEditForm.classify" placeholder="请选择事件分类"
                           class="inputStyle">
                  <el-option label="环境" value="环境"></el-option>
                  <el-option label="柴发控制器" value="柴发控制器"></el-option>
                  <el-option label="锂电池BMS" value="锂电池BMS"></el-option>
                  <el-option label="电源变换器" value="电源变换器"></el-option>
                  <el-option label="监控自定义" value="监控自定义"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事件码" prop="code">
                <el-input v-bind:disabled="codeNeedVal" v-model="addOrEditForm.code" placeholder="如:1001"
                          autocomplete="off" class="inputStyle"/>
                <el-tooltip effect="dark" placement="top-start">
                  <div style="margin-top: 5px" slot="content">事件码格式如下：</div>
                  <div style="margin-top: 5px" slot="content">1、当事件分类为环境时，格式为1开头的四位数字</div>
                  <div style="margin-top: 5px" slot="content">2、当事件分类为柴发控制器时，码格式为2开头的四位数字</div>
                  <div style="margin-top: 5px" slot="content">3、当事件分类为锂电池BMS时，格式为3开头的四位数字</div>
                  <div style="margin-top: 5px" slot="content">4、当事件分类为电源变换器时，码格式为4开头的四位数字</div>
                  <div style="margin-top: 5px" slot="content">9、当事件分类为监控自定义时，码格式为9开头的四位数字</div>
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="事件等级" prop="level">
                <el-select v-model="addOrEditForm.level" placeholder="请选择事件等级" class="inputStyle" @change="levelChange">
                  <el-option label="1级" value="1"></el-option>
                  <el-option label="2级" value="2"></el-option>
                  <el-option label="3级" value="3" style="color:red"></el-option>
                </el-select>
                <el-tooltip effect="dark" placement="top-start">
                  <div style="margin-top: 5px" slot="content">用于羲和光柴储能监控平台显示：</div>
                  <div style="margin-top: 5px" slot="content">1、1级、2级显示在事件列表中</div>
                  <div style="margin-top: 5px" slot="content">2、3级显示在告警列表中</div>
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="包含子事件" prop="isTop">
                <el-select v-model="addOrEditForm.isTop" placeholder="是否包含子事件" class="inputStyle">
                  <el-option label="否" value="0"></el-option>
                  <el-option label="是" value="1" style="color:red"></el-option>
                </el-select>
                <el-tooltip effect="dark" content="如果包含子事件，则事件名称中必须包含 * 替换符" placement="top-start">
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="所属组件" prop="deviceTypeId">
                <el-select v-model="addOrEditForm.deviceTypeId" placeholder="请选择所属组件" class="inputStyle" clearable>
                  <el-option v-for="item in deviceTypeArr" :key="item.id" :label="item.majorClassName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事件名称" prop="name">
                <el-input v-model="addOrEditForm.name" placeholder="光伏模块通讯中断" autocomplete="off" class="inputStyle"/>
                <el-tooltip effect="dark" placement="top-start">
                  <div style="margin-top: 5px" slot="content">当事件分类为【锂电池BMS】或【电源变换器】时，事件名称中必须带有组件序号替换符 *</div>
                  <div style="margin-top: 5px" slot="content">如定义为【光伏模块*风扇故障】，那么显示为【光伏模块3风扇故障】</div>
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发生名称" prop="type1Name">
                <el-input type="text" v-model="addOrEditForm.type1Name" placeholder="如:告警" class="inputStyle"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="解除名称" prop="type0Name">
                <el-input type="text" v-model="addOrEditForm.type0Name" placeholder="如:告警解除" class="inputStyle"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发生钉钉通知" prop="type1DDNotify">
                <el-select v-model="addOrEditForm.type1DDNotify" placeholder="事件发生是否钉钉通知" class="inputStyle"
                           @change="notifyChange($event, 1)">
                  <el-option label="是" value="1" style="color:red"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
                <el-tooltip effect="dark" content="事件发生之后，是否需要钉钉通知" placement="top-start">
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="解除钉钉通知" prop="type0DDNotify">
                <el-select v-model="addOrEditForm.type0DDNotify" placeholder="事件解除是否钉钉通知" class="inputStyle"
                           @change="notifyChange($event, 2)">
                  <el-option label="是" value="1" style="color:red"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
                <el-tooltip effect="dark" content="事件解除之后，是否需要钉钉通知" placement="top-start">
                  <i class="el-icon-warning" style="cursor:pointer;margin-left: 10px"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="事件说明" prop="remark" class="eventRemark">
                <el-input type="textarea" v-model="addOrEditForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleAddOrEdit" :loading="addOrEditLoading">{{ addOrEditBtnText }}
          </el-button>
          <el-button @click="addOrEditFormVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getEventsDefineList, queryEventsDefineByParam, addEventsDefine,
  modifyEventsDefine, delEventsDefine, getDeviceTypeList
} from '../../api/sys/eventDefineApi'
import NavBreadcrumb from "../../components/NavBreadcrumb";

export default {
  name: "UserManage",
  components: {NavBreadcrumb},
  data() {
    // 自定义事件码验证
    let validateCode = (rule, value, callback) => {

      if (this.codeNeedVal == false) {

        if (value === '') {

          callback(new Error('请输入事件码'));
        } else {

          let reg = '', regErrMes;
          let classifyType = this.addOrEditForm.classify;
          if (classifyType === '环境') {

            reg = /^1[0-9]{3}$/;
            regErrMes = '环境类型事件码格式为：1开始的四位数字';
          } else if (classifyType === '柴发控制器') {

            reg = /^2[0-9]{3}$/;
            regErrMes = '柴发控制器类型事件码格式为：2开始的四位数字';
          } else if (classifyType === '锂电池BMS') {

            reg = /^3[0-9]{3}$/;
            regErrMes = '锂电池BMS类型事件码格式为：3开始的四位数字';
          } else if (classifyType === '电源变换器') {

            reg = /^4[0-9]{3}$/;
            regErrMes = '电源变换器类型事件码格式为：4开始的四位数字';
          } else if (classifyType === '监控自定义') {

            reg = /^9[0-9]{3}$/;
            regErrMes = '监控自定义类型事件码格式为：9开始的四位数字';
          }
          if (reg === '') {

            return callback(new Error('请先选择事件分类'));
          }
          if (reg.test(value)) {

            let params = {code: value};
            queryEventsDefineByParam(params).then((res) => {

              if (res.data.length === 0) {

                callback();
              } else {

                return callback(new Error('该事件码已经存在'));
              }
            });
          } else {

            return callback(new Error(regErrMes));
          }
        }
      } else {

        callback();
      }
    };
    // 自定义事件名称验证
    let validateName = (rule, value, callback) => {

      if (value === '') {

        callback(new Error('请输入事件名称'));
      } else {

        let isTop = this.addOrEditForm.isTop;
        if (isTop === '1') {

          if (value.indexOf("*") === -1) {

            return callback(new Error("此分类的事件名称必须带有序号替换符号*"));
          } else {

            callback();
          }
        } else {

          callback();
        }
      }
    };
    let validateDeviceType = (rule, value, callback) => {

      let isTop = this.addOrEditForm.isTop;
      if (isTop === '1') {

        if (this.addOrEditForm.deviceTypeId === '' || this.addOrEditForm.deviceTypeId === null) {

          return callback(new Error("选择包含子事件后必须选择组件类型"));
        } else {

          callback();
        }
      } else {

        callback();
      }
    };
    return {

      codeNeedVal: false,
      dialogTitle: '',
      addOrEditBtnText: '',
      // table数据
      tableData: [],
      // 总条数
      tableTotal: 0,
      // 加载圈
      listLoading: false,
      curr: 1,
      size: 10,
      searchForm: {

        code: '',
        classify: '',
        name: '',
        level: '',
        type1DDNotify: '',
        type0DDNotify: '',
        isTop: ''
      },
      formRules: {
        code: [{validator: validateCode, trigger: ['blur', 'change']}],
        name: [{validator: validateName, trigger: ['blur', 'change']}],
        deviceTypeId: [{validator: validateDeviceType, trigger: ['blur', 'change']}],
        classify: [{required: true, message: '事件分类为必选项', trigger: 'change'}],
        type1Name: [{required: true, message: '请输入事件发生名称', trigger: ['blur', 'change']}],
        type0Name: [{required: true, message: '请输入事件解除名称', trigger: ['blur', 'change']}]
      },
      deviceTypeArr: {},
      addOrEditForm: {},
      addOrEditFormVisible: false,
      addOrEditLoading: false
    };
  },
  methods: {
    // 查询时间定义列表
    getTableData() {

      let params = {

        curr: this.curr,
        size: this.size,
        code: this.searchForm.code,
        classify: this.searchForm.classify,
        name: this.searchForm.name,
        level: this.searchForm.level,
        type1DDNotify: this.searchForm.type1DDNotify,
        type0DDNotify: this.searchForm.type0DDNotify,
        isTop: this.searchForm.isTop
      };
      this.listLoading = true;
      getEventsDefineList(params).then((res) => {

        this.tableTotal = res.data.totalNum;
        this.tableData = res.data.listResult;
        this.listLoading = false;
      }).catch(err => console.error(err));
    },
    queryDeviceTypeDefine() {

      getDeviceTypeList({}).then((res) => {

        console.info(res.data.listResult);
        this.deviceTypeArr = res.data.listResult;
      });
    },
    // 处理每页显示数量
    handleSizeChange(val) {

      this.size = val;
      this.getTableData();
    },
    // 处理分页
    handleCurrentChange(val) {

      this.curr = val;
      this.getTableData();
    },
    // 打开添加事件定义界面
    openAddEventsDefinePage() {

      this.queryDeviceTypeDefine();
      // 清除上次表单校验
      this.$nextTick(() => {

        if (this.$refs.addOrEditForm !== undefined) {

          this.$refs.addOrEditForm.resetFields();
        }
      });
      this.addOrEditForm = {

        code: '',
        name: '',
        level: '1',
        type1Name: '',
        type0Name: '',
        type1DDNotify: '0',
        type0DDNotify: '0',
        type1PatrolNotify: '0',
        type0PatrolNotify: '0',
        classify: '',
        deviceTypeId: '',
        isTop: '0',
        processTime: '',
        remark: ''
      };
      this.codeNeedVal = false;
      this.dialogTitle = '新增事件定义';
      this.addOrEditBtnText = '添加';
      this.addOrEditFormVisible = true;
    },
    // 打开添加事件定义界面
    openEditEventsDefinePage(index, row) {

      this.queryDeviceTypeDefine();
      this.codeNeedVal = true;
      this.dialogTitle = '修改事件定义';
      this.addOrEditBtnText = '修改';
      this.addOrEditFormVisible = true;
      let params = {

        id: row.id
      };
      queryEventsDefineByParam(params).then((res) => {

        // 取的是数组，但由于是id查询所以，结果必为1，取0号位
        this.addOrEditForm = res.data[0];
      });
    },
    // 提交添加或修改请求
    handleAddOrEdit() {

      if (this.addOrEditBtnText === '添加') {

        this.$refs["addOrEditForm"].validate((valid) => {

          if (valid) {

            this.addOrEditLoading = true;
            addEventsDefine(this.addOrEditForm).then(() => {

              this.addOrEditLoading = false;
              this.addOrEditFormVisible = false;
              this.getTableData();
            }).catch(err => {

              console.error(err);
            });
          } else {

            return false;
          }
        });
      } else if (this.addOrEditBtnText === '修改') {

        this.$refs["addOrEditForm"].validate((valid) => {

          if (valid) {

            this.addOrEditLoading = true;
            modifyEventsDefine(this.addOrEditForm).then((res) => {

              this.addOrEditLoading = false;
              this.addOrEditFormVisible = false;
              this.getTableData();
            }).catch(err => {

              console.error(err);
            });
          } else {

            return false;
          }
        });
      }
    },
    // 提交删除用户请求
    handleDelete(index, row) {

      this.$confirm('是否需要删除？', '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {

        let params = {

          id: row.id
        };
        delEventsDefine(params).then(() => {

          this.getTableData();
        }).catch(err => console.error(err));
      });
    },
    // 日期格式化
    dateFormat(row, column, cellValue) {

      let oDate = new Date(cellValue);
      var oYear = oDate.getFullYear(),
          oMonth = oDate.getMonth() + 1,
          oDay = oDate.getDate(),
          oHour = oDate.getHours(),
          oMin = oDate.getMinutes(),
          oSec = oDate.getSeconds();
      return oYear + '-' + this.getzf(oMonth) + '-' + this.getzf(oDay) + ' ' + this.getzf(oHour) + ':' + this.getzf(oMin) + ':' + this.getzf(oSec);
    },
    getzf(num) {

      if (parseInt(num) < 10) {

        num = '0' + num;
      }
      return num;
    },
    // 事件选项发生改变后触发提醒
    levelChange(val) {

      if (val === '3') {

        this.$alert('3级为严重等级，此类事件将出现在羲和光柴储能监控平台的告警列表中！', '请注意', {confirmButtonText: '确定', type: 'warning'});
      }
    },
    // 通知功能发生改变后触发提醒
    notifyChange(val, type) {

      if (val === '1') {

        let msg = '';
        if (type === 1) {

          msg = '此选项将开启【事件发生后钉钉通知功能】';
        } else if (type === 2) {

          msg = '此选项将开启【事件解除后钉钉通知功能】';
        } else if (type === 3) {

          msg = '此选项将开启【事件发生后巡检通知功能】';
        } else if (type === 4) {

          msg = '此选项将开启【事件解除后巡检通知功能】';
        }
        this.$alert(msg, '请注意', {confirmButtonText: '确定', type: 'warning'});
      }
    }
  },
  mounted() {

    this.getTableData();
  }
}
</script>

<style scoped>
.eventsDefineManage >>> .eventsDefine-dialog {

  width: 1000px;
  height: auto !important;
  margin-top: 80px !important;
}

.eventsDefineManage >>> .btn-custom-cancel {

  float: right;
  margin-left: 10px;
}

.inputStyle {

  width: 260px;
}
.eventRemark {

  width: 100%;
}
.eventRemark >>> .el-form-item__content {

  width: 80%;
}
</style>
<style>
</style>
