<template>
<!--    <el-breadcrumb separator="/" class="panel-style">-->
<!--        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item :to="{ path: '/home' }">{{this.$router.currentRoute.meta.parentName}}</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>{{this.$router.currentRoute.name}}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
</template>

<script>
    export default {
        name: "NavBreadcrumb"
    }
</script>

<style scoped>

</style>
